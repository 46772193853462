.grid__container {
  display: grid !important;
  gap: 5px !important;
}

/********************************
  1 image
 ********************************/
.grid__container-1 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows:  repeat(1, 1fr)
}

.grid__template-1-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

/********************************
  2 images
 ********************************/
.grid__container-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows:  repeat(2, 1fr)
}

.grid-template-2-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
}

.grid-template-2-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 4;
}

/********************************
  3 images
 ********************************/
.grid__container-3 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows:  repeat(2, 1fr)
}

.grid-template-3-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
}

.grid-template-3-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-3-3 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 4;
}


/********************************
  4 images
 ********************************/
.grid__container-4 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows:  repeat(3, 1fr)
}

.grid-template-4-1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 4;
}

.grid-template-4-2 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-4-3 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-4-4 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
}

/********************************
  5 images
 ********************************/

.grid__container-5 {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows:  repeat(3, 1fr)
}

.grid-template-5-1 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}

.grid-template-5-2 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 3;
}

.grid-template-5-3 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-5-4 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-5-5 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 4;
}

/********************************
  6 images
 ********************************/
.grid__container-6 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows:  repeat(2, 1fr)
}

.grid-template-6-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-6-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-6-3 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-6-4 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-6-5 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-6-6 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

/********************************
  7 images
 ********************************/
.grid__container-7 {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows:  auto;
}

.grid-template-7-1 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-7-2 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-7-3 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-7-4 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-7-5 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-7-6 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-7-7 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 4;
}

/********************************
  8 images
 ********************************/
.grid__container-8 {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows:  repeat(3, 1fr)
}

.grid-template-8-1 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-8-2 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-8-3 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-8-4 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-8-5 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-8-6 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-8-7 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-8-8 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 4;
}

/********************************
  9 images
 ********************************/
.grid__container-9 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows:  repeat(3, 1fr)
}

.grid-template-9-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-9-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-9-3 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-9-4 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-9-5 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-9-6 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-9-7 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-9-8 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-9-9 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
}

/********************************
  10 images
 ********************************/
.grid__container-10 {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows:  repeat(4, 1fr)
}

.grid-template-10-1 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-10-2 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-template-10-3 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-10-4 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
}

.grid-template-10-5 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-10-6 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-10-7 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 4;
}

.grid-template-10-8 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
}

.grid-template-10-9 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 5;
}

.grid-template-10-10 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 4;
  grid-row-end: 5;
}
