@import "reset";
@import "grid-images";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*::-webkit-scrollbar {
  display: none;
}

p, img {
  user-select: none;
}

.allowCopy * {
  user-select: unset;
}

.slick-next {
  right: -125px;
}

.slick-prev {
  left: -125px;
}

.slick-prev, .slick-next {
  width: 200px;
  height: 80vh;
  opacity: 0.5;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

.slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.slick-prev:before, .slick-next:before {
  display: none;
}

.react-player {
  video {
    object-fit: cover;
  }
}

.PhoneInputInput {
  border-radius: 4px;
  border: 1px solid #4C4B4C;
  padding: 14.5px 16px;
  background: #161516;
  color: #F4F3F4;
  font-size: 16px;
}

textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  opacity: 0.6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-background-clip: text !important;
  -webkit-box-shadow: none !important;
}

.formatted_text_link {
  transition: 1.5s ease;
  text-decoration: none;
}

.formatted_text_link:hover {
  text-decoration: underline;
}
